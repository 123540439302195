<template>
  <v-container fluid>
    <v-row>
      <v-col md="6" cols="12">
        <div class="conOfHeader pl-5">
          <div class="headerOfPage">All Job Orders</div>
        </div>
      </v-col>
    </v-row>
    <div class="d-flex mb-5 justify-space-between">
      <form class="conFormSubmit conSearchForm mt-5">
        <v-row>
          <v-col md="6" sm="6" cols="12">
            <label class="eachLabel">Status</label>
            <v-select
              outlined
              v-model="search.status"
              :items="statusList"
              item-text="name"
              item-value="id"
              placeholder="Status"
              hide-details="true"
              :menu-props="{ bottom: true, offsetY: true }"
              attach
              append-icon="mdi-chevron-down"
            ></v-select>
          </v-col>

          <v-col md="6" sm="6" cols="12" class="d-flex">
            <v-col md="12" class="confBtnSec d-flex">
              <v-btn
                class="srearchBnt btnAddPrimaryOutline mr-4"
                @click="onSearch"
                :disabled="isLoadingSearch"
                :loading="isLoadingSearch"
              >
                SEARCH
              </v-btn>
              <v-btn class="clearBtn btnCancelOutline mr-4" @click="onClear">
                CLEAR
              </v-btn>
              <v-btn
                class="btnReminderOutline mr-4"
                v-if="
                  (user.parent_id == 4 && user.children_id == null) ||
                  user.parent_id == 10
                "
                :disabled="isLoading"
                :loading="isLoading"
                @click="sendReminder()"
              >
                <v-icon>mdi-bell-outline</v-icon>CEO
              </v-btn>

              <v-btn
                v-if="
                  (user.parent_id == 4 && user.children_id == null) ||
                  user.parent_id == 10
                "
                class="btnReminderOutline mr-4"
                @click="sendSalesReminder()"
                :disabled="isLoadingSales"
                :loading="isLoadingSales"
              >
                <v-icon>mdi-bell-outline</v-icon>Sales
              </v-btn>
            </v-col>
            <v-col md="12"> </v-col>
          </v-col>
        </v-row>
      </form>

      <form
        v-on:submit.prevent="onSearchTxt()"
        class="conFormSubmit conSearchForm mt-5"
      >
        <v-row>
          <v-col cols="12" class="">
            <label class="eachLabel">Search</label>
            <v-text-field
              outlined
              v-model="searchTxt"
              type="text"
              class="form-control"
              id="inputSearch"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search-addon"
              append-icon="mdi-magnify"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </form>
    </div>
    <v-row v-if="listData && listData.length > 0">
      <v-col cols="12">
        <section class="conOfProjects">
          <div class="conOfTable">
            <v-data-table
              :headers="headers"
              :items="listData"
              :items-per-page="7"
              hide-default-footer
            >
              <template v-slot:[`item.name`]="{ item }">
                <router-link
                  :to="{
                    name: 'jobOrdersProcess',
                    params: { id: item.id },
                  }"
                >
                  {{ item.name }}
                </router-link>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom :left="true" :offset-y="true">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn dark icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="jobOrderPreview(item)">
                      <v-list-item-title class="statusTxt">
                        Preview
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                <div>{{ item.created_at | formatDate }}</div>
              </template>
            </v-data-table>
          </div>
        </section>
        <div
          class="conOfPagination pr-5 pt-5"
          v-if="pagination.total > pagination.per_page"
        >
          <v-pagination
            :total-visible="7"
            v-model="pagination.current_page"
            :length="pagination.last_page"
            @input="goToPage(pagination.current_page)"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <v-row v-else>
      <v-col cols="12" class="mt-7">
        <EmptyState />
      </v-col>
    </v-row>
    <v-snackbar
      v-model="successSnackbar"
      color="success"
      shaped
      absolute
      top
      right
      :timeout="timeout"
    >
      {{ successMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      color="red"
      shaped
      top
      right
      :timeout="timeout"
    >
      {{ errorMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
import EmptyState from "@/modules/shared/components/emptystate";

import { validationMixin } from "vuelidate";
import { mapGetters } from "vuex";

export default {
  mixins: [validationMixin],
  validations() {
    const validations = {
      formItem: {},
    };
    return validations;
  },
  data: () => ({
    successSnackbar: false,
    errorSnackbar: false,
    timeout: 3000,
    successMessage: "",
    errorMessage: "",
    dialogProject: false,
    dialogConform: false,
    isLoadingSave: false,
    isLoading: false,
    isLoadingSales: false,
    isLoadingSuppliers: false,
    suppliers: [],
    isLoadingListRequests: false,
    noChooseJobOrderReq: false,
    isLoadingRequestsWithSalesApprove: false,
    requestID: 1,
    requestList: [
      {
        id: 1,
        name: "Purchasing Type",
      },
      {
        id: 2,
        name: "Printing Type",
      },
      {
        id: 3,
        name: "Production Type",
      },
      {
        id: 4,
        name: "Photography Type",
      },
    ],
    statusList: [
      {
        id: 1,
        name: "Create Job Order",
      },
      {
        id: 2,
        name: "Sales Reject",
      },
      {
        id: 3,
        name: "Sales Approve",
      },
      {
        id: 4,
        name: "Cost Control Hold",
      },
      {
        id: 5,
        name: "Cost Control Approve",
      },
      {
        id: 6,
        name: "Finance Reject",
      },
      {
        id: 7,
        name: "Finance Approve Job Order",
      },
      {
        id: 8,
        name: "Finance Send to CO",
      },
      {
        id: 9,
        name: "CEO Reject",
      },
      {
        id: 10,
        name: "CEO Approve",
      },
      {
        id: 14,
        name: "Have PO",
      },
      {
        id: 15,
        name: "Sample Quotation",
      },
    ],
    formItems: {},
    isLoadingSearch: false,

    editedIndex: -1,
    listRequestsWithSalesApprove: [],
    lisRequestOrg: null,
    filteredSalesApprove: null,

    model: [],
    pagination: {
      current_page: 1,
    },
    formItem: {
      request_ids: null,
      actual_costs: null,
    },
    defaultItem: {
      request_ids: null,
      actual_costs: null,
    },
    searchTxt: "",
    search: {
      status: "",
    },
    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
        sortable: false,
      },
      { text: "STATUS", value: "status_message" },
      { text: "CREATE DATE", value: "created_at" },
      { text: "", value: "actions", sortable: false },
    ],
    listData: [],
    actionNeeded: null,
  }),
  components: { EmptyState },
  methods: {
    sendReminder() {
      this.isLoading = true;
      console.log("Joborder_id");
      apiServices.post("job-orders/reminder").then((res) => {
        if (res) {
          this.successMessage = res.message;
          this.successSnackbar = true;
          this.isLoading = false;
          console.log("Joborder_id", this.$route.params.id);
        } else {
          this.errorMessage = res.data.error || "Somthing Error";
          this.errorSnackbar = true;
          this.isLoading = false;
        }
      });
    },
    sendSalesReminder() {
      this.formItems.sales = 1;
      this.isLoadingSales = true;

      apiServices.post("job-orders/reminder", this.formItems).then((res) => {
        if (res) {
          this.successMessage = res.message;
          this.successSnackbar = true;
          this.isLoadingSales = false;
        } else {
          this.errorMessage = res.data.error || "Somthing Error";
          this.errorSnackbar = true;
          this.isLoadingSales = false;
        }
      });
    },
    changeRequestApprove(id, isChecked) {
      if (!isChecked) this.listRequestsWithSalesApprove[id].actual_costs = "";
    },
    createNewJobOrder() {
      this.getRequestsSalveApprove(1);
      this.dialogProject = true;
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    onSearch() {
      this.pagination.current_page = 1;
      this.pathByOaramSearch();
    },
    onSearchTxt() {
      this.pagination.current_page = 1;
      this.pathByOaramSearchTxt();
    },
    onClear() {
      this.search.status = "";
      this.pathByOaramSearch();
    },
    pathByOaramSearch() {
      const query = Object.entries(this.search).reduce((acc, [key, val]) => {
        if (!val) return acc;
        return { ...acc, [key]: val };
      }, {});
      if (JSON.stringify(this.updatedFilter) != JSON.stringify(query)) {
        this.$router.replace({
          name: "AlljobOrdersRequests",
          query: query,
        });
        this.updatedFilter = query;
      }
    },
    pathByOaramSearchTxt() {
      const query = Object.entries(this.searchTxt).reduce((acc, [key, val]) => {
        if (!val) return acc;
        return { ...acc, [key]: val };
      }, {});
      if (JSON.stringify(this.updatedFilterTxt) != JSON.stringify(query)) {
        this.$router.replace({
          name: "AlljobOrdersRequests",
          query: query,
        });
        this.updatedFilterTxt = query;
      }
    },
    jobOrderPreview(item) {
      this.isLoading = true;
      apiServices.get(`job-orders/${item.id}`).then((res) => {
        if (res) {
          window.open(res.data.url, "name").focus();
          this.isLoading = false;
        } else this.isLoading = false;
      });
    },
    saveItem() {
      // first iteration for get required Elements
      // second iteration for get filtered items has costs
      // third iteration get strings items

      this.$v.$touch();
      if (this.$v.$error) return;

      this.filteredSalesApprove = this.listRequestsWithSalesApprove.filter(
        (obj) => {
          return obj.isChecked == obj.id && obj.cost.cost_name;
        }
      );

      if (Object.keys(this.filteredSalesApprove).length) {
        this.noChooseJobOrderReq = false;
        let request_ids = [];
        let actual_costs = [];
        this.filteredSalesApprove.filter((obj) => {
          request_ids.push(obj.id);
          actual_costs.push(obj.cost.cost_name);
        });
        this.formItem.request_ids = request_ids.join();
        this.formItem.actual_costs = actual_costs.join();
        this.isLoadingSave = true;
      } else {
        this.noChooseJobOrderReq = true;
        return;
      }

      let formItems = {
        ...this.formItem,
        project_id: String(this.$route.params.id),
        type_id: String(this.requestID),
      };

      apiServices.post("job-orders", formItems).then((res) => {
        if (res) {
          this.pagination.current_page = 1;
          this.getListData(this.$route.params.id, this.pagination.current_page);
          this.isLoadingSave = false;
          this.closeProjectModal();
        } else {
          this.isLoadingSave = false;
        }
      });
    },
    closeProjectModal() {
      this.dialogProject = false;
      this.filteredSalesApprove = [];
      this.listRequestsWithSalesApprove = this.lisRequestOrg;
      this.$nextTick(() => {
        this.$v.$reset();
        this.formItem = Object.assign({}, this.defaultItem);
      });
    },
    goToPage(page) {
      this.getListData(page);
      window.scrollTo(0, 0);
    },
    getListData(page) {
      this.listData = [];
      this.isLoading = true;
      true,
        apiServices
          .get(
            `job-order/all?per_page=7&page=${page}&status=${this.search.status}&search=${this.searchTxt}`
          )
          .then((res) => {
            if (res) {
              false, (this.listData = res.data);
              this.pagination = res.meta;
              this.isLoading = false;
            } else this.isLoading = false;
          });
    },
    getSuppliersNames(id) {
      this.suppliers = [];
      this.isLoadingSuppliers = true;
      true,
        apiServices.get(`projects/suppliers/${id}`).then((res) => {
          if (res) {
            false, (this.suppliers = res.data);
            this.isLoadingSuppliers = false;
          } else this.isLoadingSuppliers = false;
        });
    },
    getRequestsSalveApprove(id) {
      this.listRequestsWithSalesApprove = [];
      this.isLoadingListRequests = true;
      this.isLoadingRequestsWithSalesApprove = true;
      apiServices
        .get(
          `requests?per_page=500&type_id=${id}&project_id=${this.$route.params.id}&status=6`
        )
        .then((res) => {
          if (res) {
            this.listRequestsWithSalesApprove = res.data;
            this.lisRequestOrg = JSON.parse(JSON.stringify(res.data));
            this.isLoadingRequestsWithSalesApprove = false;
            this.isLoadingListRequests = false;
          } else {
            this.isLoadingRequestsWithSalesApprove = false;
            this.isLoadingListRequests = false;
          }
        });
    },
    changeRequestID() {
      this.getRequestsSalveApprove(this.requestID);
    },
  },

  created() {
    if (this.$route.query.status) {
      this.search.status = this.$route.query.status;
    }
    this.getListData(this.pagination.current_page);
  },
  watch: {
    dialogProject(val) {
      val || this.closeProjectModal();
    },
    "$route.params.query": {
      handler: function () {
        this.getListData(this.pagination.current_page);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["user"]),
    formTitle() {
      return this.editedIndex === -1 ? "Add New Request" : "Edit Request";
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_job-orders.scss";
</style>
